import React from "react"
import "../styles/global.scss"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Link } from "gatsby"
import Reveal from "./reveal"
import { GatsbyImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet"

import { motion } from "framer-motion"

const shortcodes = { Link, GatsbyImage }
const IMAGE_KEY = "image"

export default function Postlayout({
  data: { mdx },
  pageContext: { next, previous },
}) {
  // const location = { pathname: `/blog/${mdx.frontmatter.slug}` }
  const embeddedImagesByKey =
    mdx.frontmatter.embeddedImages &&
    mdx.frontmatter.embeddedImages.reduce((images, image, index) => {
      images[`${IMAGE_KEY}${index + 1}`] = images[
        `${IMAGE_KEY}${index + 1}`
      ] || {
        ...image.childImageSharp,
      }
      return images
    }, {})

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Lance / {mdx.frontmatter.title}</title>
        <link rel="preload" href="/fonts/fonts.css" as="style"></link>
        <body className="scrollSmooth" />
      </Helmet>
      <>
        <div className="contentPost">
          <div className="flex flex-row md:flex-col md:mb-44 md:mt-16">
            <div className="">
              <h1 className="md:text-4xl font-medium">
                {mdx.frontmatter.title}
              </h1>
              <motion.div className="inline-block md:mb-6 mt-1">
                <Link
                  to="/insights"
                  className="text-sm px-2 py-1 hover:text-black hover:bg-white hover:subpixel-antialiased border-solid border-realGray-300 border rounded-full"
                >
                  Go back
                </Link>
              </motion.div>

              {/* <div className="uppercase text-xs font-medium">
                {mdx.frontmatter.category} — {mdx.timeToRead} Minutes To Read
              </div> */}
            </div>
            <div className="w-full mt-4 md:mt-0">
              <GatsbyImage
                image={mdx.frontmatter.featuredImage.childImageSharp.gatsbyImageData}
                className="rounded-lg" />
            </div>
          </div>

          <div style={{ margin: `0 auto`, maxWidth: 700 }} className="font-light">
            <MDXProvider components={shortcodes}>
              <MDXRenderer embeddedImages={embeddedImagesByKey}>
                {mdx.body}
              </MDXRenderer>
            </MDXProvider>
          </div>
          <Reveal>
            <div className="grid grid-cols-2 mt-24 md:mt-60 pb-16">
              <div className="justify-self-start col-start-1 col-end-2">
                {previous && (
                  <>
                    <Link
                      className="inline-block"
                      to={`../${previous.frontmatter.slug}`}
                    >
                      <div className="transXb">
                        <h3 className="">{previous.frontmatter.title}</h3>
                        <span className="blackBlue">Previous</span>
                      </div>
                    </Link>
                  </>
                )}
              </div>
              <div className="justify-self-end col-start-2 col-end-3">
                {next && (
                  <>
                    <Link
                      className="inline-block"
                      to={`../${next.frontmatter.slug}`}
                    >
                      <div className="transXf">
                        <h3 className="">{next.frontmatter.title}</h3>
                        <span className="blackBlue">Next</span>
                      </div>
                    </Link>
                  </>
                )}
              </div>
            </div>
          </Reveal>
        </div>
      </>
    </React.Fragment>
  );
}

export const query = graphql`query ($id: String) {
  mdx(id: {eq: $id}) {
    id
    body
    frontmatter {
      title
      category
      embeddedImages {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      featuredImage {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
    timeToRead
  }
}
`
